import React from 'react';
import Head from 'next/head';
import { Roboto } from '@next/font/google';
import { AppContextProvider } from '../context';
import 'react-datepicker/dist/react-datepicker.css';
import '../public/style/bootstrap.css';
import '../public/style/theme.css';

const roboto = Roboto({
  subsets: ['latin-ext'],
  weight: ['400', '700'],
});

function MyApp({ Component, pageProps, err }) {
  return (
    <>
      <Head>
        <title>Habitat Coins | Habitat Venezuela</title>
      </Head>
      <AppContextProvider className={roboto.className}>
        <Component {...pageProps} err={err} />
      </AppContextProvider>
    </>
  );
}

export default MyApp;
