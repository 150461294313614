/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { onAuthStateChanged, User as FirebaseUser } from 'firebase/auth';
import {
  ref,
  onValue,
  query,
  orderByChild,
  equalTo,
  Unsubscribe,
} from 'firebase/database';
import { useRouter } from 'next/router';
import { Product, User } from '../models';
import { auth, database } from '../lib/firebase';

type TAppContext = {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  product: Product;
  setProduct: React.Dispatch<React.SetStateAction<Product>>;
};

export const AppContext = React.createContext<TAppContext>(null);

interface AppContextProviderProps {
  children: React.ReactNode;
  className?: string;
}

export function AppContextProvider({
  children,
  className = '',
}: AppContextProviderProps) {
  const router = useRouter();
  const [user, setUser] = React.useState<User>(null);
  const [firebaseUser, setFirebaseUser] = React.useState<FirebaseUser>(null);
  const [product, setProduct] = React.useState<Product>(null);
  React.useEffect(function syncAuthState() {
    const unsubscribe = onAuthStateChanged(
      auth,
      (_user) => {
        if (_user && _user.uid) {
          setFirebaseUser(_user);
        } else {
          router.push('/sign-in');
        }
      },
      (error) => {
        console.log(error);
      }
    );
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);
  React.useEffect(
    function syncUserData() {
      let unsubscribe: Unsubscribe;
      if (firebaseUser?.uid) {
        unsubscribe = onValue(
          query(
            ref(database, 'users'),
            orderByChild('id'),
            equalTo(firebaseUser?.uid)
          ),
          (snapshot) => {
            if (snapshot.exists()) {
              const data = snapshot.val();
              const [key] = Object.keys(data);
              setUser({
                firstName: data?.[key]?.first_name,
                secondName: data?.[key]?.second_name,
                firstLastname: data?.[key]?.first_lastname,
                secondLastname: data?.[key]?.second_lastname,
                phone: data?.[key]?.phone,
                email: data?.[key]?.email,
                answer: data?.[key]?.answer,
                profession: data?.[key]?.profession,
                dni: data?.[key]?.dni,
                id: data?.[key]?.id,
                idUser: data?.[key]?.id_user ?? key,
                points: data?.[key]?.points,
                refer: data?.[key]?.refer,
                birthdate: data?.[key]?.birthdate,
                status: data?.[key]?.status,
                photo: data?.[key]?.photo,
              });
            } else {
              router.push('/sign-in');
            }
          }
        );
      }
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    },
    [firebaseUser?.uid]
  );
  const value = React.useMemo(
    () => ({ user, setUser, product, setProduct }),
    [product, user]
  );
  return (
    <AppContext.Provider value={value}>
      <main className={className}>{children}</main>
    </AppContext.Provider>
  );
}
